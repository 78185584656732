<template>
  <v-main>

    <v-container
        id="claim-container"
        fluid
        tag="section"
    >

        <base-material-card
            icon="mdi-family-tree"
            class="px-5 py-3"
            ref="form"
        >

          <div>
            <v-alert
                type="error"
                outlined
                v-for="(error, i) in errors"
                :key="i"
            >
              {{ error }}
            </v-alert>
          </div>

          <v-form ref="claimForm"
                  v-model="valid"
                  @submit.prevent="validate"
                  lazy-validation
          >

            <v-row>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-select
                    v-model="computedDependent"
                    label="Dependent"
                    id="dependent"
                    :items="dependentList"
                    item-text="name"
                    item-value="id"
                    return-object
                    :rules="rules.required"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="9"
              >
                <v-text-field
                    v-model="claim.cause"
                    label="Cause of Death"
                    id="cause"
                    :rules="rules.required"
                ></v-text-field>
              </v-col>

            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="3"
              >
                <v-text-field
                    v-model="computedIncidentDate"
                    label="Incident Date"
                    id="incident-date"
                    type="date"
                    :rules="rules.required"
                ></v-text-field>
              </v-col>

            </v-row>


            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <div class="my-2">
                  <v-btn
                      color="primary"
                      dark
                      type="submit"
                      id="submitClaim"
                  >
                    Submit
                  </v-btn>
                </div>
              </v-col>
            </v-row>

          </v-form>


        </base-material-card>

    </v-container>

  </v-main>
</template>

<script>
  import api from '@/services/api'
  import {mapGetters} from 'vuex'
  import validation from '@/utils/validation'
  import geoUtils from '@/utils/geography'
  import constants from '@/utils/constants'
  import moment from 'moment'

  export default {
    name: 'CoreClaimView',

    created() {
      this.userId = this.getUserId
      this.claimId = this.$route.params.id
      this.statesList = geoUtils.fetchUsStates()
      this.countriesList = geoUtils.fetchCountries()
      this.fetchDependents()
      if (this.claimId) {
        this.fetchClaim()
      }
    },

    computed: {
      ...mapGetters({
        getUserId: 'getUserId'
      }),
      computedCountry: {
        get() {
          return geoUtils.findCountryByCode(this.claim.country)
        },
        set(value) {
          this.claim.country = value
        }
      },
      computedDependent: {
        get() {
          if (this.claim.dependent === undefined) {
            return
          }
          return this.dependentList.filter(dep => {
            return this.claim.dependent.id === dep.id
          })[0]
        },
        set(value) {
          this.claim.dependent = value
        }
      },
      dependentList: {
        get() {
          let list = []
          this.dependents.forEach(dep => {
            if (dep.status !== constants.DEPENDENT_STATUS_APPROVED) {
              return
            }

            let item = {
              id: dep.id,
              name: `${dep.names} (${dep.relationType.name})`
            }
            list.push(item)
          })
          return list
        }
      },
      computedIncidentDate: {
        get() {
          return moment(this.claim.incidentDate).format('YYYY-MM-DD')
        },
        set(value) {
          this.claim.incidentDate = value
        }
      }
    },

    methods: {
      validate() {
        if(this.$refs.claimForm.validate()) {
          if (this.claim.id !== undefined) {
            return this.updateClaim()
          }
          this.addClaim()
        }
      },
      fetchDependents() {
        api
            .fetchDependents(this.userId)
            .then(response => {
              this.dependents = response.data
            })
            .catch(err => {
              this.handleError(err)
            })
      },
      fetchClaim() {
        api
            .fetchClaim(this.userId, this.claimId)
            .then(response => {
              this.claim = response.data
            })
            .catch(err => {
              this.handleError(err)
            })
      },
      addClaim() {
        this.$gtag.event('submit_claim')

        let payload = {
          dependentId: this.claim.dependent.id,
          incidentDate: moment(this.claim.incidentDate).format('YYYY-MM-DD'),
          cause: this.claim.cause
        }

        api
            .addClaim(this.userId, payload)
            .then(response => {
              if (response.status === 201) {
                this.$router.push(`/claims/view`)
              }
            })
            .catch(err => {
              this.handleError(err)
            })
      },
      updateClaim() {
        this.$gtag.event('update_claim')

        let payload = {
          dependentId: this.claim.dependent.id,
          incidentDate: this.claim.incidentDate,
          cause: this.claim.cause
        }
        api
            .updateClaim(this.userId, this.claimId, payload)
            .then(response => {
              if (response.status === 200) {
                this.$router.push('/claims/view')
              }
            })
            .catch(err => {
              this.handleError(err)
            })
      },
      handleError(err) {
        let errorMessage = api.parseResponseError(err)
        this.errors.push(errorMessage)
      }
    },

    data() {
      return {
        valid: true,
        errors: [],
        userId: null,
        claimId: null,
        user: {},
        claim: {},
        dependents: [],
        genderOptions: [
          'Male',
          'Female',
          'Other'
        ],
        relationTypes: [],
        countriesList: [],
        rules: {
          required: [
            v => !!v || 'required'
          ],
          numeric: [
            v => validation.isInteger(v) || 'must be a number'
          ],
          birthYear: [
            v => validation.isInteger(v) || 'must be a year number',
            v => validation.isMoreThan(v, 1900) || 'must be after 1900',
            v => validation.isBeforeOrEqualToCurrentYear(v) || 'must be before or equal to current year'
          ]
        }
      }
    }
  }
</script>
